import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Text } from 'grommet';

import StickyCartHeader from './StickyCartHeader';
import discountSavingsMessage from '../../lib/cart/discountSavingsMessage';
import selectMemberName from '../../state/industry/selectMemberName';
import selectReferralCode from '../../state/industry/selectReferralCode';
import useCart from './useCart';

const CartDiscountHeader = ({ onClose }) => {
  const {
    orderDiscounts,
    savedOnPaint,
    savedOnSupplies,
    discountCode,
    lineItems,
  } = useCart();
  const referralCode = useSelector(selectReferralCode);
  const memberName = useSelector(selectMemberName);

  const mainDiscountText = discountSavingsMessage({
    orderDiscounts,
    discountCode,
    savedOnPaint,
    savedOnSupplies,
    industryReferralCode: referralCode,
    industryMemberName: memberName,
    lineItems,
  });

  return (
    <StickyCartHeader onClose={onClose}>
      <Text color="white" size="small" weight={500}>
        {mainDiscountText}
      </Text>
      <Text color="white" size="small" weight={400}>
        Fast shipping, easy returns.
      </Text>
    </StickyCartHeader>
  );
};

CartDiscountHeader.propTypes = {
  onClose: PropTypes.func,
};

export default memo(CartDiscountHeader);
