import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import styled from 'styled-components';

const StickyContainer = styled(Box)`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const StickyCartHeader = ({ children }) => {
  return (
    <StickyContainer
      direction="row"
      justify="between"
      align="center"
      pad={{ horizontal: 'medlarge', vertical: 'xsmall' }}
      border={{ side: 'bottom', size: 'small', color: 'black' }}
      background="black"
      className="cart-header"
      height={{ min: '4em' }}
    >
      <Box pad={{ right: '1.6em' }}>{children}</Box>
    </StickyContainer>
  );
};

StickyCartHeader.propTypes = {
  children: PropTypes.node,
};

export default StickyCartHeader;
