import { createSelector } from 'reselect';
import { get } from '../../lib/nodash';

const selectMemberName = createSelector(
  (state) => state.industry,
  (industry) => {
    return get('member.first_name', industry)
      ? `${get('member.first_name', industry)} ${get(
          'member.last_name',
          industry
        )}`
      : undefined;
  }
);

export default selectMemberName;
