import { get, head, filter } from '../nodash';
import formatCurrency from '../formatCurrency';
import deNodify from '../deNodify';
import { isWallcovering } from '../product';

const discountSavingsMessage = ({
  orderDiscounts = [],
  discountCode,
  industryReferralCode,
  savedOnPaint,
  savedOnSupplies,
  industryMemberName,
  lineItems = [],
}) => {
  const industryDiscountActive =
    industryReferralCode &&
    (discountCode === industryReferralCode || !discountCode) &&
    orderDiscounts.length > 0;

  const codeDiscounts = filter(
    { __typename: 'DiscountCodeApplication' },
    orderDiscounts
  );

  const onlyWallcoverings = deNodify(lineItems).every((x) => {
    return isWallcovering(x.variant);
  });

  const appliedDiscountAmount = get('value', head(codeDiscounts));

  const discountAllocationText =
    get('valueType', head(codeDiscounts)) !== 'percent'
      ? formatCurrency(appliedDiscountAmount)
      : `${get('value', head(codeDiscounts))}${
          get('valueType', head(codeDiscounts)) === 'percent' ? '%' : ''
        }`;

  if (industryDiscountActive) {
    return `Hey ${industryMemberName}, your Industry pricing of ${discountAllocationText} is applied below`;
  }

  if (orderDiscounts.length > 0 && discountCode && appliedDiscountAmount > 0) {
    return `You are saving ${discountAllocationText} with the discount code ${discountCode}!`;
  }

  if (savedOnPaint > 0) {
    const amountSaved = savedOnSupplies
      ? savedOnPaint + savedOnSupplies
      : savedOnPaint;
    const savingsProducts = savedOnSupplies ? 'paint and supplies' : 'paint';
    return `You saved ${formatCurrency(amountSaved)} on ${savingsProducts}!`;
  }

  if (savedOnSupplies > 0) {
    return `You saved ${formatCurrency(savedOnSupplies)} on supplies!`;
  }

  if (onlyWallcoverings) {
    return null;
  }

  return null;
};

export default discountSavingsMessage;
